import { css, Global, Theme } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';

type Size = {
    mobileS: string;
    mobileM: string;
    mobileL: string;
    tablet: string;
    laptop: string;
    laptopL: string;
    desktop: string;
    desktopL: string;
};

const size: Size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '400px',
    tablet: '630px',
    laptop: '1030px',
    laptopL: '1280px',
    desktop: '1580px',
    desktopL: '1820px',
};

export const theme: Theme = {
    colors: {
        yellow1: '#F9EBD3',
        yellow2: '#EDCC8F',
        yellow3: '#BB8624',
        yellow4: '#95660C',
        purple3: '#901557',
        gray1: '#FCFCFC',
        gray4: '#6B6A64',
        gray5: '#1C1B1A',
    },
    device: {
        mobileS: `(min-width: ${size.mobileS})`,
        mobileM: `(min-width: ${size.mobileM})`,
        mobileL: `(min-width: ${size.mobileL})`,
        tablet: `(min-width: ${size.tablet})`,
        laptopS: `(min-width: ${size.tablet}) and (orientation:landscape)`,
        laptop: `(min-width: ${size.laptop}) and (orientation:landscape)`,
        laptopL: `(min-width: ${size.laptopL})`,
        desktop: `(min-width: ${size.desktop})`,
        desktopL: `(min-width: ${size.desktopL})`,
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={() => css`
                ${emotionNormalize};
                html,
                body {
                    scroll-behavior: smooth;
                    background: ${theme.colors.gray1};
                }
                body {
                    font-weight: normal;
                    font-size: 1rem;
                    word-wrap: break-word;
                    font-kerning: normal;
                    overflow-x: hidden;
                    word-wrap: break-word;
                    margin: 0;
                    font-family: 'Poppins';
                    -webkit-font-smoothing: antialiased;
                }
                a {
                    text-decoration: none;
                }
                .container,
                .large-container {
                    margin: 0px 16px;
                }
                ul {
                    padding: 0;
                }
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p,
                ul,
                li,
                button,
                div {
                    margin: 0;
                }
                .link {
                    font-weight: 700;
                    text-decoration: none;
                    color: ${theme.colors.purple3};
                }

                button {
                    border: none;
                    outline: none;
                    background: none;
                }
                .header {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 152.5%;
                    color: ${theme.colors.gray5};
                }
                .header-2 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 138.5%;
                    color: ${theme.colors.yellow3};
                }
                p,
                a,
                li {
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 152.5%;
                    color: ${theme.colors.gray5};
                }
                @media ${theme.device.mobileL} {
                    .container,
                    .large-container {
                        margin: 0px 16px;
                    }
                }
                @media ${theme.device.tablet} {
                    .container,
                    .large-container {
                        max-width: 720px;
                        margin: 0 auto;
                    }
                }
                @media ${theme.device.laptop} {
                    .container {
                        max-width: 980px;
                    }
                    .large-container {
                        max-width: 1020px;
                    }
                    .header {
                        font-weight: 700;
                        font-size: 48px;
                        line-height: 152.5%;
                    }
                    .header-2 {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 40px;
                        line-height: 138.5%;
                    }
                }
                @media ${theme.device.laptopL} {
                    .container {
                        max-width: 1064px;
                        margin: 0 auto;
                    }
                    .large-container {
                        max-width: 1280px;
                    }
                }
                @media ${theme.device.desktopL} {
                    .container {
                        margin: 0 auto;
                    }
                }
            `}
        />
    );
};
