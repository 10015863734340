import { GlobalStyles, theme } from '@/GlobalStyles';
import { Nav } from '@/layout/Nav';
import { ThemeProvider } from '@emotion/react';
import { createContext, FC, useContext, useState } from 'react';

type SiteState = boolean;

type SiteContext = {
    active: SiteState;
    setActive: React.Dispatch<React.SetStateAction<SiteState>>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const siteContext = createContext<SiteContext>(null as any);

export const useSiteContext = (): SiteContext => useContext(siteContext);

export const LayoutProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [active, setActive] = useState<SiteState>(false);

    return (
        <siteContext.Provider value={{ active, setActive }}>
            <ThemeProvider theme={theme}>
                <Nav />
                <GlobalStyles />
                {children}
            </ThemeProvider>
        </siteContext.Provider>
    );
};
