import styled from '@emotion/styled';
import { theme } from '../GlobalStyles';
import { ReactComponent as ArrowShort } from '../../images/svgs/arrow-short.svg';
import { Link } from 'gatsby';

interface PrimaryProps {
    fill?: boolean;
}

const { colors, device } = theme;

export const ButtonPrimary = styled.button<PrimaryProps>`
    background: ${({ fill }) => (fill ? `${theme.colors.purple3}` : `none`)};
    color: ${({ fill }) => (fill ? `${colors.gray1}` : `${theme.colors.purple3}`)};
    border: ${({ fill }) =>
        fill ? `2px solid ${theme.colors.purple3}` : `2px solid ${theme.colors.purple3}`};
    font-size: 16px;
    border-radius: 5px;
    font-weight: 500;
    padding: 14px 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in;
    font-family: 'Montserrat';
    font-weight: 600;
    cursor: pointer;
    svg {
        width: 18px;
        transition: 0.2s ease-in;
        margin-right: 12px;
        fill: ${({ fill }) => (fill ? `${theme.colors.gray1}` : `${theme.colors.purple3}`)};
    }
    &:hover {
        background: ${({ fill }) => (fill ? `none` : `${theme.colors.purple3}`)};
        color: ${({ fill }) => (fill ? `${theme.colors.purple3}` : `${theme.colors.gray1}`)};
        svg {
            fill: ${({ fill }) => (fill ? `${theme.colors.purple3}` : `${theme.colors.gray1}`)};
        }
    }
`;

interface Color {
    color: string;
}

const StyledSecondaryButton = styled.button<Color>`
    border: none;
    cursor: pointer;
    font-family: 'Montserrat';
    text-transform: capitalize;
    background: none;
    line-height: 24px;
    padding-left: 0px;

    a {
        display: flex;
        align-items: center;
        font-weight: 800;
        font-size: 16px;
        color: ${({ color }) => color === 'purple' && `${theme.colors.purple3}`};
    }
    svg {
        color: ${({ color }) => color === 'purple' && `${theme.colors.purple3}`};
        fill: ${theme.colors.purple3};
        width: 16px;
    }
    div {
        transform: rotate(-43deg);
        margin-left: 16px;
        padding: 10px;
        display: flex;
        transition: 0.2s ease-in;
        align-items: center;
        justify-content: center;
        border-radius: 200px;
        border: ${({ color }) => color === 'purple' && `2px solid ${theme.colors.purple3}`};
    }
    &:hover {
        div {
            transform: rotate(00deg);
        }
    }
    @media ${device.laptop} {
        font-size: 18px;
    }
    @media ${device.desktop} {
        font-size: 20px;
    }
`;

interface Props {
    children: string | React.ReactNode;
    color: 'purple';
    className?: string;
    link: string;
}
export const SecondaryButton = ({ children, color, link }: Props) => {
    return (
        <StyledSecondaryButton color={color}>
            <Link to={link}>
                {children}
                <div>
                    <ArrowShort />
                </div>
            </Link>
        </StyledSecondaryButton>
    );
};
