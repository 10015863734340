export const information = {
    sanBernardino: {
        location: '1848 Commercenter DR E, San Bernardino, CA 92408',
        locationUrl:
            'https://www.google.com/maps/place/Brenda+Evans+Louka+DDS+(San+Bernardino,+CA)/@34.0681333,-117.2826205,15z/data=!4m2!3m1!1s0x0:0xb641372a7c9a76e9?sa=X&ved=2ahUKEwjSq6n-o7P-AhXRi1wKHRJJAxYQ_BJ6BAhKEAg',
        phone: '(909) 382-0255',
    },
    elCajon: {
        location: '1276 E Washington Ave, El Cajon, CA 92019',
        locationUrl:
            'https://www.google.com/maps/place/Brenda+Evans+Louka+DDS+-+El+Cajon,+CA/@32.7878709,-116.9366024,17z/data=!4m15!1m8!3m7!1s0x80d9590acac8480d:0xbd9a719063d9e893!2s1276+E+Washington+Ave,+El+Cajon,+CA+92019,+USA!3b1!8m2!3d32.7878709!4d-116.9366024!16s%2Fg%2F11c0rlxvn9!3m5!1s0x80d9597eafcb3c45:0xce5575b10885c1b6!8m2!3d32.787854!4d-116.9365735!16s%2Fg%2F11nxq8wpf1',
        phone: '(619) 440-9211',
    },
    email: '',
};
