export const navMenu = [
    {
        name: 'About Us',
        path: '/about-us/',
        menu: [
            { name: 'San Bernardino Office', path: '/about-us/' },
            { name: 'El Cajon Office', path: '/el-cajon/about-us/' },
            { name: 'Virtual Tour', path: '/virtual-tour/' },
        ],
    },
    {
        name: 'San Bernardino',
        path: '/our-services/',
        menu: [
            {
                name: 'Cosmetic Dentistry',
                path: '/our-services/cosmetic-dentistry/',
            },
            {
                name: 'Dental Implants',
                path: '/our-services/dental-implants/',
            },
            {
                name: 'Family Dentistry',
                path: '/our-services/family-dentistry/',
            },
            {
                name: 'Oral Surgery',
                path: '/our-services/oral-surgery/',
            },
            {
                name: 'Orthodontics',
                path: '/our-services/orthodontics/',
            },
            {
                name: 'Periodontics',
                path: '/our-services/periodontics/',
            },
            {
                name: 'Root Canals',
                path: '/our-services/root-canal-treatments/',
            },
        ],
    },
    {
        name: 'El Cajon',
        path: '/el-cajon/our-services/',
        menu: [
            {
                name: 'Cosmetic Dentistry',
                path: '/el-cajon/cosmetic-dentistry/',
            },
            {
                name: 'Dental Implants',
                path: '/el-cajon/dental-implants/',
            },
            {
                name: 'Family Dentistry',
                path: '/el-cajon/family-dentistry/',
            },
            {
                name: 'Oral Surgery',
                path: '/el-cajon/oral-surgery/',
            },
            {
                name: 'Orthodontics',
                path: '/el-cajon/orthodontics/',
            },
            {
                name: 'Periodontics',
                path: '/el-cajon/periodontics/',
            },
            {
                name: 'Root Canals',
                path: '/el-cajon/root-canals/',
            },
        ],
    },
    { name: 'Forms', path: '/forms/' },
];
